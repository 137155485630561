import { Mesh } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import endGlbStr from '../models/end-point.gltf?raw';

class EndPoint {

    static loader = new GLTFLoader();

    static pr: Promise<Mesh> | null = null;

    static async draw() {
        if (!EndPoint.pr) {
            EndPoint.pr = new Promise((resolve, reject) => {
                this.loader.parse(endGlbStr,
                    '',
                    ({ scene: object }) => resolve(object.children[0] as Mesh),
                    e => reject(e)
                );
            });
            return EndPoint.pr;
        }

        return (await EndPoint.pr).clone();
    }
}

export default EndPoint;
